import { Endpoints, fetchData, strapiOptions } from '@/shared/api/data-fetcher';
import { TSeoType } from './seo';
import { TAdComponent } from '@/features/ad/model';
import { RawCarousel } from '@/features/carousel/model';
import { RawIframeBlock } from '@/features/iframe-block/model';
import { RawCarouselPromo } from '@/features/carouselPromo/model';
import { THorizontalPills } from '@/components/HorizontalPills/model';

export const fetchModularPagesByDomain = async (domain: string): Promise<Array<IModularPageBase>> => {
    const url = Endpoints.getModularPagesByDomain(domain),
        result = await fetchData(url, strapiOptions);
    return result;
};
export const fetchModularPagesByQuery = async (query: string): Promise<Array<Partial<IModularPageBase>>> => {
    const url = Endpoints.getModularPagesByDomain(query),
        result = await fetchData(url, strapiOptions);
    return result;
};

export interface IModularPageBase {
    id: number;
    name: string;
    slug: string;
}

export type Ad = { dimensions: string; ad_id: string };

export interface IModularPage extends IModularPageBase {
    customScripts: string | null;
    createdAt: Date;
    updatedAt: Date;
    publishedAt: Date;
    seo: TSeoType | null;
    content: Array<TAdComponent | RawCarousel | RawCarouselPromo | RawIframeBlock | THorizontalPills>;
    analyticId: string | null;
}

export const initModularPage: IModularPage = {
    id: 0,
    name: '',
    slug: '',
    customScripts: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    publishedAt: new Date(),
    seo: null,
    content: [],
    analyticId: null,
};

export const fetchModularPageById = async (id: number): Promise<IModularPage> => {
    const url = Endpoints.getModularPageById(id);
    const pagesData = await fetchData(url, strapiOptions);

    return pagesData;
};
