import { $arenaDataStore } from '@/app.model';
import { createEvent, createStore, sample } from 'effector';
import { buildTheySellLibPath } from '@/features/ad/model';
export const SDK_ENV = {
    development: 'DEV' as const,
    production: 'PROD' as const,
    staging: 'STAGING' as const,
};
export const arkadiumSdkLoaded = createEvent<TArkadiumSdkStore>();

export type TArkadiumSdkStore = ArkadiumSdkInstance | null;
export const $arkadiumSdkStore = createStore<TArkadiumSdkStore>(null);
export const $videoAdUrl = createStore<string>(process.env.VIDEO_ADS);

sample({
    clock: $arenaDataStore,
    fn: (data) => {
        if (data.theySellAd) {
            const url = buildTheySellLibPath(process.env.VIDEO_ADS, data.theySellAd.displayAdUrl);
            return url;
        }
        return process.env.VIDEO_ADS;
    },
    target: $videoAdUrl,
});
sample({
    clock: arkadiumSdkLoaded,
    target: $arkadiumSdkStore,
});

//setting up custom domain for they sell
sample({
    clock: $arkadiumSdkStore,
    source: $arenaDataStore,
    fn: (arenaData, sdk) => {
        if (!arenaData || !sdk) {
            return null;
        }
        if (arenaData.theySellAd?.videoAdUrl) {
            sdk.ads?.setVideoAdsDomain(arenaData.theySellAd.videoAdUrl);
        }
        return null;
    },
});
