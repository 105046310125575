'use client';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {AnalyticsContextView} from '@/features/arena-data/view';
import {TSideBar} from './model';
import {useDeviceDetector} from '@/shared/utils/userAgentContext';
import {ETabIndexesOrderingLevels} from '@/shared/app/types';
import styles from './styles.module.css';

export const BURGER_INPUT_ID = 'sidebarToggle';
const BUTTON_ID = 'sidebarButton';

export const SideBar = ({ zIndexStartVal, attributes }: any) => {
    const props = attributes as TSideBar;
    const { isDesktop } = useDeviceDetector();
    const isDesktopDetected = isDesktop();
    const {AITracks} = useContext(AnalyticsContextView);
    const [isSideMenuOpen, setIsSideMenuOpen] = useState<boolean>(false);
    const [isClick, setIsClick] = useState<boolean>(false);
    const { groups, topbarLinks } = props;

    const styleOverride = (pairs: any) => {
        const validPairs = pairs.filter(([, value]: any) => !!value);
        if (!validPairs?.length) {
            return {};
        }
        return validPairs.reduce((styleObj: any, [cssVar, value]: any) => {
            styleObj[cssVar] = value;
            return styleObj;
        }, {});
    };

    const sideBarButtonOverrides = styleOverride([
        ['--icon-color', props.override_color_sidebar_toggle],
        ['--icon-background', props.override_color_sidebar_toggle_background],
    ]);

    const sideBarOverrides = styleOverride([
        ['--background-color', props.override_color_sidebar_background],
        ['--divider-color', props.override_color_sidebar_divider],
    ]);

    const sideBarItemOverrides = styleOverride([
        ['--normal-label-color', props.override_color_item_normal_label],
        ['--hover-background-color', props.override_color_item_hover_background],
        ['--hover-border-color', props.override_color_item_hover_border],
    ]);

    const getStyle = () =>
        `
            ${
                props?.sidebarHiddenClosedState
                    ? `
                :root {
                    --sidebar-width-closed: 0 !important;
                }`
                    : ''
            }
            ${
                props?.groups?.length <= 0 && props?.topbarLinks?.length > 0
                    ? `
                .ark-ui-sidebar.sidebar .divider.mobile{
                    display: none !important;
                }
                @media (min-width: 1025px){
                    :root {
                        --sidebar-width-closed: 0 !important;
                        --sidebar-width-open: 0 !important;
                    }
                }
                `
                    : ''
            }
        `;

    const inputOnChange = (ev: any) => {
        const isChecked = ev?.currentTarget?.checked;
        setIsSideMenuOpen(isChecked);
    };
    const inputOnFocus = () => setTimeout(() => typeof document !== 'undefined' &&
                (document as any)?.getElementById?.(BURGER_INPUT_ID)?.focus?.()
            , 100); // this is to fix focus lost and not trackable on rerender
    const lastItemOnBlur = () => {
        const input: any = document?.getElementById?.(BURGER_INPUT_ID);
        if (input) {
            input.checked = false;
            setIsSideMenuOpen(false);
        }
    };
    const noop = () => {};

    useEffect(() => {
        isSideMenuOpen && !isClick && setTimeout(() => (document?.querySelector?.(`.sidebar .item`) as HTMLElement)?.focus?.(), 200);
    }, [isSideMenuOpen]);

    return (
        <>
            <div style={{ zIndex: zIndexStartVal + 2 }} className={'ark-ui-sidebar'}>
                <input aria-label="Toggle sidebar menu" id={BURGER_INPUT_ID} type="checkbox" className={styles.input}
                       tabIndex={ETabIndexesOrderingLevels.FIRST}
                       onChange={inputOnChange}
                       onFocus={inputOnFocus}
                       onMouseUp={() => setIsClick(true)}
                       onKeyUp={() => setIsClick(false)}
                />
                <div className={BUTTON_ID} style={sideBarButtonOverrides} tabIndex={ETabIndexesOrderingLevels.NOINTERACTION}>
                    <svg
                        className="hamburger"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M3 12H21M3 6H21M3 18H21"
                            stroke="var(--icon-color)"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    <svg
                        className="close"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M18 6L6 18M6 6L18 18"
                            stroke="var(--icon-color)"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
                <nav className="ark-ui-sidebar sidebar" style={sideBarOverrides}>
                    {topbarLinks?.length && !isDesktopDetected && (
                        <>
                            <div className="group topbarLinks mobile">
                                {topbarLinks.map((l, index) => (
                                    <a
                                        key={index}
                                        className="item"
                                        href={l.href || ''}
                                        onClick={() => AITracks.topNavClick(l?.label, index)}
                                        target={l.target || '_self'}
                                        style={sideBarItemOverrides}
                                        tabIndex={isSideMenuOpen ? ETabIndexesOrderingLevels.FIRST : ETabIndexesOrderingLevels.NOINTERACTION}
                                    >
                                        <span className="label __max12ch">{l?.label?.substring(0, 12) || ''}</span>
                                    </a>
                                ))}
                            </div>
                            <div className="divider mobile"></div>
                        </>
                    )}

                    {groups.map((g, i) => (
                        <React.Fragment key={i}>
                            <div className={`group sidebarLinks${g.withBackground ? ' bg' : ''}`}>
                                {g.link && (
                                    <a
                                        className="item heading"
                                        href={g.link.href || '#'}
                                        target={g.link.target || '_self'}
                                        tabIndex={
                                            g.link.href && isSideMenuOpen
                                                ? ETabIndexesOrderingLevels.OTHER_AUTO
                                                : ETabIndexesOrderingLevels.NOINTERACTION
                                        }
                                        style={sideBarItemOverrides}
                                        onClick={() => AITracks.sideNavClick(g?.link?.label, i)}
                                    >
                                        {g.icon && (
                                            <img
                                                loading="lazy"
                                                className="itemIcon"
                                                src={g.icon}
                                                alt="Thumbnail"
                                                tabIndex={ETabIndexesOrderingLevels.NOINTERACTION}
                                            />
                                        )}
                                        <span
                                            className="label __max12ch"
                                            tabIndex={ETabIndexesOrderingLevels.NOINTERACTION}
                                        >
                                            {g.link.label?.substring(0, 12) || ''}
                                        </span>
                                    </a>
                                )}

                                {g.sidebar_items?.map((item, j) => (
                                    <a
                                        key={j}
                                        className="item"
                                        href={item.link?.href || '#'}
                                        target={item.link?.target || '_self'}
                                        tabIndex={isSideMenuOpen ? ETabIndexesOrderingLevels.FIRST : ETabIndexesOrderingLevels.NOINTERACTION}
                                        onClick={() => AITracks.sideNavClick(item?.link?.label, `${i}-${j}`)}
                                        data-order={`${j}/${g.sidebar_items.length - 1} -> ${i}/${groups.length - 1}`}
                                        onBlur={isSideMenuOpen && i === groups.length - 1 && j === g.sidebar_items.length - 1
                                            ? lastItemOnBlur
                                            : noop
                                        }
                                        style={sideBarItemOverrides}
                                    >
                                        {item.icon && (
                                            <img
                                                loading="lazy"
                                                className="itemIcon"
                                                src={item.icon}
                                                aria-hidden="true"
                                                alt="Thumbnail"
                                                tabIndex={ETabIndexesOrderingLevels.NOINTERACTION}
                                            />
                                        )}
                                        <span
                                            className="label __max12ch"
                                            tabIndex={ETabIndexesOrderingLevels.NOINTERACTION}
                                        >
                                            {item.link?.label?.substring(0, 12) || ''}
                                        </span>
                                    </a>
                                ))}
                            </div>
                            {i < groups.length - 1 && <div className="divider"></div>}
                        </React.Fragment>
                    ))}
                </nav>
            </div>

            <label
                htmlFor="sidebarToggle"
                className="invisibleOverlay"
                style={{ zIndex: zIndexStartVal }}
                aria-label="Toggle sidebar menu"
            ></label>
        </>
    );
};
