import { TFingerPrintStore } from '@/features/analytics/Fingerprint/model';

// **Global types for reusability and consistency**
export enum PageTypes {
    Home = 'Home',
    Category = 'Category',
    AllCategories = 'AllCategories',
    Game = 'Game',
    NotFound = 'NotFound',
    Profile = 'Profile',
    PrivacyPolicy = 'PrivacyPolicy',
    PrivacyPolicyCalifornia = 'PrivacyPolicyCalifornia',
    Subscription = 'Subscription',
    AboutUs = 'AboutUs',
    GiftCard = 'GiftCard',
    SiteMap = 'SiteMap',
    CoBranded = 'CoBranded',
    BlogArchive = 'BlogArchive',
    BlogPost = 'BlogPost',
}
export enum ComponentsNames {
    Carousel = 'carousel',
    AdComponent = 'ad-component',
    StandardCard = 'game-standard-card',
    HeroCard = 'game-hero-card',
    MarketingCard = 'marketing-card',
    GroupCards = 'game-group-cards',
    SeoCard = 'seo-card',
    IframeBlock = 'iframe-block',
}

export enum ETabIndexesOrderingLevels {
    NOINTERACTION = -1,
    AUTO = 0,
    FIRST = 1,
    SECOND = 2,
    THIRD = 3,
    OTHER_AUTO = 4,
};

export interface CMSComponentProperties {
    id: number;
    __component: `web-components.${ComponentsNames}`;
}
export interface CMSTimestamps {
    createdAt?: Date;
    updatedAt?: Date;
    publishedAt?: Date;
}

export interface RawGameCardInfo {
    id: number;
    slug: string;
    name: string;
    extId: number;
    description: string | null;
    graphic_56x56: string | null;
    graphic_592x400: string | null;
    graphic_288x192: string | null;
    graphic_288x152: string | null;
    graphic_288x232: string | null;
    graphic_184x400: string | null;
    icon_graphic_112x112: string | null;
    icon_graphic_128x128: string | null;
}

export type TGameCardButtonProps = {
    buttonLinkText: GameCardButton['label'];
    buttonLinkHref: GameCardButton['url'];
    buttonLinkTarget: GameCardButton['actionTarget'];
    analyticId: GameCardButton['analyticId'];
};

export type GameCardButton = {
    id: number;
    label: string | null;
    url: string | null;
    analyticId: string | null;
    actionTarget: '_blank' | '_self';
};

export type GameCardBadge = {
    id: number;
    title: string | null;
    backgroundColor: THexColor | null;
    enable: boolean;
    icon: string | null;
};

export type THexColor = `#${string}`;

export type TGameCardAnalytics = TGameCardFingerPrintAnalytics &
    TGameCardModularPageAnalytics &
    TGameCardArenaAnalytics;
export type TGameCardFingerPrintAnalytics = { fingerprintData: TFingerPrintStore };
export type TGameCardModularPageAnalytics = { modularPage: { slug: string; analyticId: string | null } };
export type TGameCardArenaAnalytics = { arena: { isMinimalistic: boolean; analyticId: string | null } };
