'use client'
import {useState, useEffect, useMemo, useCallback, useContext} from 'react';
import Fuse, { IFuseOptions } from 'fuse.js';
import { useDebounce } from '@uidotdev/usehooks';
import {AnalyticsContextView} from "@/features/arena-data/view";

export const useSearch = (
    data: any[],
    options?: IFuseOptions<any>,
    rerankResults?: (results: any[]) => any[],
    debounceDelay: number = 200
) => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState<any[] | null>(null);
    const [isSearching, setIsSearching] = useState(false);
    const debouncedQuery = useDebounce(query, debounceDelay);
    const {AITracks} = useContext(AnalyticsContextView);

    // Memoize the Fuse.js instance to avoid re-creating it on every render
    const fuse = useMemo(() => new Fuse(data, options), [data, options]);

    // Memoize the search function
    const search = useCallback(
        (searchQuery: string) => {
            let searchResults: any[] = [];
            if (searchQuery !== '') {
                searchResults = fuse.search(searchQuery);
            }
            // Apply custom reranking logic if provided
            if (rerankResults) {
                searchResults = rerankResults(searchResults);
            }
            setResults(searchResults);
        },
        [fuse, rerankResults]
    );

    useEffect(() => {
        setIsSearching(true);
        if (debouncedQuery.length > 2) {
            AITracks.searchBoxQuery(debouncedQuery);
            search(debouncedQuery);
            setIsSearching(false);
        } else {
            setResults(null);
        }
    }, [debouncedQuery, search]);

    return { query, setQuery, results, isSearching };
};
