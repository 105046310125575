import { createEffect, createEvent, createStore, sample } from 'effector';
import { fallbackLng } from '@/i18n/settings';
import { fetchArenaData, fetchModularPagesByDomain } from '@/shared/api';
import { ArenaDataState, Category } from '@/shared/api/arena-data';
import { Languages } from '@/shared/app/languages';
import { IModularPageBase } from '@/shared/api/pages';
import { ALL_CATEGORY } from '@/shared/utils/constants';

export const HOMEPAGE_SLUG_LIST = ['home', '', null, undefined, '/'];
export const languageChanged = createEvent<Languages>();
export const $languageStore = createStore<Languages>(fallbackLng).on(languageChanged, (_, payload) => payload);

export const initArenaStore: ArenaDataState = {
    layout: null,
    customScripts: null,
    analyticId: null,
    game_page: null,
    logo: null,
    favIcon: null,
    game_feed: null,
    arena_categories: null,
    arena_tags: null,
    arena_badges: null,
    localeList: null,
    categoriesSlug: null,
    theySellAd: null,
    external_scripts: null,
};
export const $arenaDataStore = createStore<ArenaDataState>(initArenaStore);
export const fetchArenaDataFx = createEffect(fetchArenaData);
export const $arenaDataError = createStore<string | null>(null).on(
    fetchArenaDataFx.failData,
    (_, payload) => payload.message
);

sample({
    clock: fetchArenaDataFx.doneData,
    fn: (data) => {
        //set up first locale in the list to cookies
        return data;
    },
    target: $arenaDataStore,
});

sample({
    clock: fetchArenaDataFx.doneData,
    fn: (data) => {
        if (!data) {
            return 'no arena data; ';
        }
        let info = '';
        if (!data?.layout) {
            info += 'no layout; ';
        }
        if (!data.game_feed?.feedId) {
            info += 'no game feed; ';
        }
        if (info) {
            return info;
        }
        return null;
    },
    target: $arenaDataError,
});

export const $pagesStore = createStore<Array<IModularPageBase>>([], { name: 'pages' });
export const $homePageStore = createStore<IModularPageBase | null>(null, { name: 'mainPage' });
export const fetchPagesDataFx = createEffect(fetchModularPagesByDomain);
export const pageNotFound = createEvent<string>();
export const pageNotFoundFx = createEffect(pageNotFound);
export const $pageNotFoundStore = createStore('');
export const $pagesDataError = createStore<string | null>(null).on(
    fetchPagesDataFx.failData,
    (_, payload) => payload.message
);
sample({
    clock: fetchPagesDataFx.doneData,
    target: $pagesStore,
});

sample({
    clock: fetchPagesDataFx.doneData,
    target: $pagesDataError,
    fn: (data) => {
        if (!data?.length) {
            return 'there are no pages set for this arena';
        }
        return null;
    },
});
sample({
    clock: fetchPagesDataFx.doneData,
    filter: (pages) => (pages && pages.length > 0) ?? false,
    // Use first page if home slug is not found
    fn: (pages) => pages?.find((p) => HOMEPAGE_SLUG_LIST.includes(p.slug)) ?? pages?.[0],
    target: $homePageStore,
});
export const langChangedFx = createEffect(languageChanged);
export const $domainStore = createStore<string | null>(null, { name: 'domain' });
export const $isMicrosoftDomain = createStore<boolean>(false, { name: 'isMicrosoftDomain' });
sample({
    clock: fetchArenaDataFx,
    target: $domainStore,
});
sample({
    clock: fetchArenaDataFx,
    fn: (domain) => Boolean(domain?.includes('microsoft') || domain?.includes('msn')),
    target: $isMicrosoftDomain,
});
sample({
    clock: pageNotFoundFx,
    fn: (pageName) => `page ${pageName} not found`,
    target: $pageNotFoundStore,
});

export const appStarted = createEffect(async (domain: string) => {
    await fetchArenaDataFx(domain);
});

export const $arenaGameFeedIdStore = createStore<number | null>(null, { name: 'arenaGameFeedId' });

export const $allCategoryData = createStore<Category>(ALL_CATEGORY);
sample({
    clock: fetchArenaDataFx.doneData,
    fn: (arenaData) => {
        const allCategory =
            arenaData.arena_categories?.find((c) => [undefined, null, ''].includes(c.slug)) ?? ALL_CATEGORY;
        return { ...allCategory, id: -1, name: ALL_CATEGORY.name };
    },
    target: $allCategoryData,
});

sample({
    clock: fetchArenaDataFx.doneData,
    filter: (data) => !!data.game_feed,
    fn: (data) => data.game_feed?.feedId ?? null,
    target: $arenaGameFeedIdStore,
});

export const THIRD_PARTY_ADS_OPT_OUT_COOKIE_NAME = '3PAdsOptOut';
export const MUID_COOKIE_NAME = 'MUID';

export enum AnalyticsPageTypes {
    GENERIC = 'generic',
    GENERIC_HOME = 'home',
    CATEGORY = 'category',
    GAMEPAGE = 'gamepage',
    SEARCH = 'search',
    SITEMAP = 'sitemap',
    TAGS = 'tags',
    UNKNOWN = 'unknown',
}
