'use client';
import React, { createContext, ReactNode, useEffect } from 'react';
import { useUnit } from 'effector-react';
import {
    $gdprConsentStore,
    $isCMPDisabled,
    $isGDPRApplies,
    $isUS,
    $msInitialConsent,
    consentStoresInitFx,
    fetchUserCountryFx,
    gdprConsentUpdated,
    msInitialConsentUpdated,
    targetingConsentUpdated,
} from '@/features/analytics/model';
import { $arenaDataStore, $isMicrosoftDomain } from '@/app.model';
import { usePathname } from 'next/navigation';
import classNames from 'classnames';
import { $gameStateStore, GameState } from '@/features/games/model';
import { TopBar } from '@/components/TopBar';
import { Footer } from '@/components/Footer';
import Script from 'next/script';
import { arkadiumSdkLoaded, SDK_ENV } from '@/features/arkadiumsdk/model';
import { ExternalScripts } from '@/features/external-scripts';
import { $externalScriptsStore, externalScriptLoaded } from '@/features/external-scripts/model';
import { SideBar } from '@/components/SideBar';
import { AITracksDefault, useAnalytics } from '@/features/analytics/AI/AITracks';
import { useWebVitalsFx } from '@/features/web-vitals/useWebVitalsFx';
import { aiAnalyticsStartedFx } from '@/features/analytics/AI/model';
import { gaAnalyticsStartedFx } from '@/features/analytics/GA/model';

type TProps = Readonly<{
    children: ReactNode;
    arenaMaxWidth: number | null | undefined;
    thirdPartyAdsOptOutCookie: string | null;
    muidCookie: string | null;
}>;
const zIndexStartVal = 100;

export const AnalyticsContextView = createContext<{ AITracks: any; AIReadyAsDep: boolean }>({
    AITracks: AITracksDefault,
    AIReadyAsDep: false,
});
const AnalyticsContextProviderView = AnalyticsContextView.Provider;

export const ArenaView = ({ children, thirdPartyAdsOptOutCookie, muidCookie }: TProps) => {
    const arenaData = useUnit($arenaDataStore);
    const externalScripts = useUnit($externalScriptsStore);
    const onScriptLoad = useUnit(externalScriptLoaded);
    const gameState = useUnit($gameStateStore);
    const sdkLoaded = useUnit(arkadiumSdkLoaded);
    const initGeoService = useUnit(fetchUserCountryFx);
    const initConsentStores = useUnit(consentStoresInitFx);
    const isCMPDisabled = useUnit($isCMPDisabled);
    const gdprConsent = useUnit($gdprConsentStore);
    const isMicrosoft = useUnit($isMicrosoftDomain);
    const initAI = useUnit(aiAnalyticsStartedFx);
    const initGA = useUnit(gaAnalyticsStartedFx);
    const updateMsInitialConsent = useUnit(msInitialConsentUpdated);
    const [AITracks, AIReadyAsDep] = useAnalytics();
    useWebVitalsFx(AITracks, AIReadyAsDep);

    const buildStrapiImage = (url?: string) => (url ? (process.env.NODE_ENV === 'development' ? `${url}` : url) : '');
    const topbarOverrides = arenaData.layout?.styleOverride?.topbar,
        topbarItemOverrides = arenaData.layout?.styleOverride?.topbarLink,
        topbarItemOverridesNormal = topbarItemOverrides?.find((i: any) => i.state === 'normal'),
        topbarItemOverridesHover = topbarItemOverrides?.find((i: any) => i.state === 'hover'),
        topbarAttributes = {
            logo: buildStrapiImage(arenaData.logo?.url),
            links: arenaData.layout?.topBarLinks,
            override_color_topbar_background: topbarOverrides?.background,
            override_color_item_normal_label: topbarItemOverridesNormal?.color,
            override_color_item_hover_background: topbarItemOverridesHover?.background,
        };

    const pathname = usePathname();
    const sidebarHiddenClosedState =
        (arenaData.layout?.topBar === false && arenaData.layout?.topBarLinks?.length > 0) ||
        arenaData.layout?.sideBar === false ||
        pathname?.includes?.('/games/');
    const sidebarData = arenaData.layout?.sidebar_groups?.map((g) => ({
            ...g,
            icon: buildStrapiImage(g.icon?.url),
            sidebar_items: g.sidebar_items?.map((i: any) => ({ ...i, icon: buildStrapiImage(i.icon?.url) })),
        })),
        sidebarOverrides = arenaData.layout?.styleOverride?.sidebar,
        sidebarItemOverrides = arenaData.layout?.styleOverride?.sidebarItem,
        sidebarItemOverridesNormal = sidebarItemOverrides?.find((i: any) => i.state === 'normal'),
        sidebarItemOverridesHover = sidebarItemOverrides?.find((i: any) => i.state === 'hover'),
        sidebarAttributes = {
            sidebarHiddenClosedState,
            groups: sidebarData,
            topbarLinks: arenaData.layout?.topBarLinks,
            override_color_item_normal_label: sidebarItemOverridesNormal?.color,
            override_color_item_hover_background: sidebarItemOverridesHover?.background,
            override_color_item_hover_border: sidebarItemOverridesHover?.border,
            override_color_sidebar_background: sidebarOverrides?.background,
            override_color_sidebar_divider: sidebarOverrides?.divider,
            override_color_sidebar_toggle: sidebarOverrides?.toggle,
            override_color_sidebar_toggle_background: sidebarOverrides?.toggleBackground || topbarOverrides?.background,
            zIndexStartVal,
        };
    const showSidebar =
        (arenaData.layout?.topBar || arenaData.layout?.sideBar) &&
        (arenaData.layout?.topBarLinks?.length > 0 ||
            sidebarData?.find((g) => g?.link?.label || g?.sidebar_items?.find((i: any) => i?.link?.label)));

    useEffect(() => {
        [GameState.GAME, GameState.REWARD, GameState.INTERSTITIAL].includes(gameState)
            ? document.body.classList.add('gameRunning')
            : document.body.classList.remove('gameRunning');
    }, [gameState]);

    useEffect(() => {
        updateMsInitialConsent({ isMicrosoft, thirdPartyAdsOptOutCookie, muidCookie });
    }, [isMicrosoft, thirdPartyAdsOptOutCookie, updateMsInitialConsent, muidCookie]);

    useEffect(() => {
        initGeoService();
        initConsentStores();
    }, [initGeoService, initConsentStores]);

    useEffect(() => {
        if (gdprConsent) {
            initAI();
            initGA();
        }
    }, [gdprConsent, initAI, initGA]);

    const footerData = {
        logo: arenaData?.layout?.footer?.logo ?? null,
        groups: arenaData?.layout?.footer?.groups ?? null,
        socialLinks: arenaData?.layout?.footer?.socialLinks ?? null,
        backgroundColor: arenaData?.layout?.footer?.background ?? null,
        fontColor: arenaData?.layout?.footer?.fontColor ?? null,
        text: arenaData?.layout?.footer?.text ?? null,
    };

    const MemoizedTopBar = React.memo(TopBar);
    const MemoizedSideBar = React.memo(SideBar);
    const onSdkLoad = async () => {
        try {
            const env = SDK_ENV[process.env.BUILD_ENV as keyof typeof SDK_ENV];
            const sdk = await window.__ArenaApi__?.getInstance(env);
            if (sdk) {
                sdkLoaded(sdk);
            }
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <>
            <AnalyticsContextProviderView value={{ AITracks, AIReadyAsDep }}>
                <Script
                    id="arena-sdk"
                    strategy="afterInteractive"
                    src="https://developers.arkadium.com/cdn/sdk/dev/v2/arena-sdk.js"
                    onLoad={onSdkLoad}
                ></Script>
                {isCMPDisabled !== null && <ExternalScripts scripts={externalScripts} onLoad={onScriptLoad} />}
                <div
                    className={classNames('data-wrapper', sidebarHiddenClosedState ? 'sidebar-collapsed-hidden' : '')}
                    data-theme={arenaData?.layout?.themeLayout?.theme}
                    data-round_corners={arenaData?.layout?.themeLayout?.roundCorners}
                    data-carousel_arrows_visible={arenaData?.layout?.themeLayout?.carouselArrowsVisible}
                    data-layout={arenaData?.layout?.themeLayout?.layout}
                    data-font={arenaData?.layout?.themeLayout?.fontFamily}
                    data-mode={arenaData?.layout?.themeLayout?.mode}
                    data-max_width={arenaData?.layout?.themeLayout?.arenaMaxWidth}
                >
                    {arenaData.layout?.topBar && (
                        <MemoizedTopBar zIndexStartVal={zIndexStartVal} attributes={topbarAttributes} />
                    )}
                    {showSidebar && <MemoizedSideBar zIndexStartVal={zIndexStartVal} attributes={sidebarAttributes} />}
                    {children}
                    <Footer attributes={footerData} />
                </div>
            </AnalyticsContextProviderView>
        </>
    );
};
