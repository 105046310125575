'use client';
import { TExternalScript } from '@/shared/api/arena-data';
import xss from 'xss';
import Script from 'next/script';
import React from 'react';
import { buildInternalScript } from './internalScripts';

type TProps = Readonly<{ scripts: TExternalScript[] | null; onLoad: (name: string) => void }>;
const getId = (prefix: string, name: string) => `${prefix}-${name.replace(' ', '-')}`;
const cleanScriptTag = (content: string) => {
    if (!content) {
        return null;
    }
    let innerContent = String(content).trim().startsWith('<script>') ? content.slice(8, -9) : content;
    return xss(innerContent).replace(/&gt;/g, '>').replace(/&lt;/g, '<');
};
const buildRawScript = (script: TExternalScript, onLoad: (name: string) => void) => {
    const allScripts = [script.codeBeforeMainScript, script.mainScript, script.codeAfterMainScript].filter(
        Boolean
    ) as string[];
    const allScriptsAsString = allScripts.map(cleanScriptTag).join('; \n');
    return (
        <Script
            strategy="afterInteractive"
            defer
            key={getId('inline-main-script', script.name)}
            id={getId('inline-main-script', script.name)}
            onReady={() => onLoad(script.name)}
        >
            {allScriptsAsString}
        </Script>
    );
};

const buildCdnUrlScript = (script: TExternalScript, onLoad: (name: string) => void) => {
    return (
        <React.Fragment key={getId('url-main-script', script.name)}>
            {script.codeBeforeMainScript && (
                <Script id={getId('before-url-main-script', script.name)} strategy="afterInteractive" defer>
                    {cleanScriptTag(script.codeBeforeMainScript)}
                </Script>
            )}

            <Script
                src={script.mainScript}
                id={getId('url-main-script', script.name)}
                strategy="afterInteractive"
                defer
                onLoad={() => {
                    onLoad(script.name);
                }}
            />

            {script.codeAfterMainScript && script.isLoaded && (
                <Script id={getId('after-url-main-script', script.name)} strategy="afterInteractive" defer>
                    {cleanScriptTag(script.codeAfterMainScript)}
                </Script>
            )}
        </React.Fragment>
    );
};

export const ExternalScripts = ({ scripts, onLoad }: TProps) => {
    if (!scripts) {
        return null;
    }
    const existingScripts = scripts.map((script) => {
        if (script.scriptLoadingType === 'rawScript') {
            return buildRawScript(script, onLoad);
        }
        if (script.scriptLoadingType === 'cdnURL') {
            return buildCdnUrlScript(script, onLoad);
        }
        return buildInternalScript(script, onLoad);
    });
    return <>{existingScripts}</>;
};
