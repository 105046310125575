import {onTTFB, onFCP, onLCP, onCLS, onINP, Metric} from 'web-vitals';
import {WebVitals} from "@/features/web-vitals/model";
import {useEffect, useRef, useState} from "react";
import {isServer} from "@/shared/app/isServer";

export const useWebVitalsFx = (AITracks: any, AIReadyAsDep: boolean) => {
    const {webVitalsAllMetrics, webVitalsSingleMetric} = AITracks;
    const [LCP, setLCP] = useState<Metric | null>(null);
    const [FCP, setFCP] = useState<Metric | null>(null);
    const [TTFB, setTTFB] = useState<Metric | null>(null);
    const [CLS, setCLS] = useState<Metric | null>(null);
    const [INP, setINP] = useState<Metric | null>(null);
    const logMetric = (metric: Metric) => metric && console.log(`WebVitals - ${metric?.name}:`, metric);
    const isInteractive = !isServer && typeof document !== "undefined";
    const metricSeparateOptions = {reportAllChanges: true};
    const metricAllHandle = () => {
        AIReadyAsDep && TTFB && FCP && LCP && CLS && webVitalsAllMetrics({
            TTFB: TTFB as Metric,
            FCP: FCP as Metric,
            LCP: LCP as Metric,
            CLS: CLS as Metric,
        });
    }

    useEffect(() => {
        if (isInteractive) {
            // ALL METRICS COLLECTION
            onTTFB(metric => metric && metric.value !== TTFB?.value && setTTFB(metric));
            onFCP(metric => metric && metric.value !== FCP?.value && setFCP(metric));
            onLCP(metric => metric && metric.value !== LCP?.value && setLCP(metric));
            // SINGLE METRICS TRACKED
            onINP(metric => metric && metric.value !== INP?.value && setINP(metric), metricSeparateOptions);
        }
        onCLS(metric => metric && metric.value !== CLS?.value && setCLS(metric), metricSeparateOptions);
        document?.documentElement?.click();
    }, [isInteractive]);

    // SINGLE METRICS TRACK
    useEffect(() => {
        logMetric(TTFB as Metric);
        CLS && AIReadyAsDep && webVitalsSingleMetric('CLS', CLS as Metric);
    }, [
        isInteractive,
        AIReadyAsDep,
        CLS,
    ]);
    useEffect(() => {
        logMetric(INP as Metric);
        INP && AIReadyAsDep && webVitalsSingleMetric('INP', INP as Metric);
    }, [
        isInteractive,
        AIReadyAsDep,
        INP,
    ]);

    // ALL METRICS TRACK
    useEffect(() => {
        metricAllHandle();
    }, [
        isInteractive,
        AIReadyAsDep,
        LCP,
        FCP,
        TTFB,
        CLS,
    ]);
}

