import { usePathname } from 'next/navigation';
import NextLink from 'next/link';
import { ComponentProps } from 'react';

function getLocaleFromPathname(pathname: string): string {
    const locale = pathname.split('/')[1];
    return locale;
}

type Props = Omit<ComponentProps<typeof NextLink>, 'href'> & {
    href: string;
};

export const LocalizedLink: React.FC<Props> = ({ href, children, ...props }) => {
    const pathname = usePathname();
    const locale = getLocaleFromPathname(pathname);

    // Check if href is a full path (starts with http:// or https://)
    const isFullPath = /^https?:\/\//.test(href);

    let hrefWithLocale = href;

    if (!isFullPath) {
        if (href.startsWith(`/${locale}`)) {
            console.warn(
                `LocalizedLink: href "${href}" already includes locale. This may lead to unexpected behavior.`
            );
            hrefWithLocale = href;
        } else {
            hrefWithLocale = `/${locale}${href}`;
        }
    }

    return (
        <NextLink href={hrefWithLocale} {...props}>
            {children}
        </NextLink>
    );
};
