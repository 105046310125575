export const getSearchParams = (queryString: string, name: string): string => {
    const search = new URLSearchParams(queryString);
    return search.get(name) ?? '';
};

export const delayNavigation = (event: any, delay = 400) => {
    setTimeout(() => {
        const href = event.target.getAttribute('href');
        const target = event.target.getAttribute('target');

        if (href) {
            if (target === '_blank') {
                window.open(href);
            } else {
                window.location.href = href;
            }
        }
    }, delay);
};

export const buildHrefLocale = (href: string, locale: string) => {
    if (href.startsWith('/')) {
        return `/${locale}${href}`;
    } else if (href.startsWith('http')) {
        return href;
    } else {
        return `/${locale}/${href}`;
    }
};

export const cleanQueryParams = (searchParams: URLSearchParams, toExclude: string[]): string => {
    const cleanedParams = new URLSearchParams(searchParams);

    Array.from(cleanedParams.keys())
        .filter((key) => toExclude.some((unwanted) => key === unwanted || key.startsWith(unwanted)))
        .forEach((key) => cleanedParams.delete(key));

    return cleanedParams.toString();
};
