import { createEvent, createStore } from 'effector';
import { useMemo } from 'react';
import { useUnit } from 'effector-react';
import { $gameFeed } from '../../../app/[locale]/games/[slug]/model';
import { $arenaDataStore } from '@/app.model';
import { DEFAULT_ROOT_CATEGORIES_SLUG } from '@/shared/utils/constants';

export const useGamefeed = () => {
    const gamefeed = useUnit($gameFeed);
    const arenaData = useUnit($arenaDataStore);
    return useMemo(() => {
        const categoryImageMap = new Map(
            arenaData?.arena_categories?.map((cat) => [cat.name?.toLowerCase() ?? '', cat.image?.url ?? '']) ?? []
        );

        return (gamefeed ?? []).map((game) => {
            return {
                ...game,
                meta: {
                    ...(game.meta ?? {}),
                    categories: (game.meta?.categories ?? []).map((category) => {
                        const formattedName = category
                            .split(' ')
                            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                            .join(' ');
                        return {
                            name: formattedName,
                            image: categoryImageMap.get((category ?? '').toLowerCase()) ?? '',
                            slug: arenaData?.categoriesSlug ?? DEFAULT_ROOT_CATEGORIES_SLUG,
                        };
                    }),
                },
            };
        });
    }, [gamefeed, arenaData]);
};

interface SearchResult {
    query: string;
    results: any[];
}

export const $searchResults = createStore<SearchResult>({ query: '', results: [] });
export const updateSearchResults = createEvent<SearchResult>();

$searchResults.on(updateSearchResults, (_, payload) => payload);
