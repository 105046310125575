import { ArenaDataState, TExternalScript } from '@/shared/api/arena-data';
import { createEvent, createStore, sample } from 'effector';

export const $externalScriptsStore = createStore<TExternalScript[] | null>(null);
export const externalScriptLoaded = createEvent<TExternalScript['name']>();
export const externalScriptsUpdated = createEvent<ArenaDataState['external_scripts']>();
sample({
    clock: externalScriptsUpdated,
    fn: (scripts) =>
        scripts?.map((externalScript) => ({
            id: externalScript.id,
            name: externalScript.name,
            ...externalScript.script,
        })) ?? null,
    target: $externalScriptsStore,
});

sample({
    clock: externalScriptLoaded,
    source: $externalScriptsStore,
    fn: (scripts, name) => {
        return scripts?.map((script) => (script.name === name ? { ...script, isLoaded: true } : script)) ?? null;
    },
    target: $externalScriptsStore,
});
