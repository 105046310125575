import { createEffect, createStore, sample } from 'effector';
import { FingerprintService } from '.';
import { gdprConsentUpdated } from '../model';

export type TFingerPrintStore = {
    fpVisitorId?: string;
    fpIncognito?: boolean;
    fpCheckDone?: boolean;
};
export const initFPStore = {
    fpVisitorId: 'unknown',
    fpIncognito: false,
    fpCheckDone: false,
};
export const fingerprintDetectFx = createEffect(async () => await FingerprintService.init());
const $isStarted = createStore<boolean>(false).on(fingerprintDetectFx.done, (_, data) => true);
export const $FingerprintStore = createStore<TFingerPrintStore>(initFPStore).on(fingerprintDetectFx.done, (_, data) =>
    Object.assign({}, data.result ? JSON.parse(data.result) : initFPStore, { fpCheckDone: true })
);

sample({
    clock: gdprConsentUpdated,
    source: $isStarted,
    filter: (isStarted, gdprConsent) => Boolean(!isStarted && gdprConsent),
    target: fingerprintDetectFx,
});
